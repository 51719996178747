/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

/* eslint-disable react/display-name */
import Image from 'next/image';
import React from 'react';

import styles from './style.module.scss';

const MarkdownRenderer = {
  link: (props: { href: string; children?: React.ReactNode }) => {
    const { href, children } = props;

    const shouldUseTargetSelf = /^(https?):\/\//.test(href)
      ? typeof window !== 'undefined'
        ? href.startsWith(window.location.origin) // In case someone pastes the full URL
        : false
      : /*
         * Anything else should use target="_self". Especially anything that starts with mailto, tel, or javascript
         */
        true;

    return (
      <a href={href} target={shouldUseTargetSelf ? '_self' : '_blank'} rel="noreferrer noopener">
        {children}
      </a>
    );
  },
  image: props => {
    const { src, title } = props;
    if (src.match(/\.(jpeg|jpg|gif|png)$/)) {
      return (
        <>
          {/* Specifically, it doesn't like that the alt says "cover image" - the word image is already used by screen readers to describe it */}
          {/* Potentially worth adding a field for supplying alt text for the article cover image? */}
          <Image
            src={src}
            style={{
              display: 'block',
              width: '80%',
              height: '400px',
              objectFit: 'cover',
              margin: '0 auto',
            }}
            alt="cover image"
          />
          {title && <span className={styles.imageAndLinkTitle}>{title}</span>}
        </>
      );
    }
    if (src.match(/\.(mp4|ogg|webm)$/)) {
      return (
        <>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video controls className={styles.videoPlayer}>
            <source src={src} />
          </video>
          {title && <span className={styles.imageAndLinkTitle}>{title}</span>}
        </>
      );
    }
    return <></>;
  },
};

export default MarkdownRenderer;
