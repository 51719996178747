import CmsImage from '@/cmsComponents/CmsImage';
import { LinkElement } from '@/cmsComponents/LinkElement';
import { MarkdownCMSModule } from '@/cmsComponents/MarkdownCMSModule';
import { Alignment, LayoutBackground } from '@/cmsComponents/constants';
import cmsStyles from '@/cmsComponents/style.module.scss';
import cx from 'classnames';
import Link from 'next/link';
import React from 'react';
import { IImageText } from 'types/cms';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { defaultAlt } from 'components/Image';

import styles from './ImageTextCover.module.scss';

interface ImageTextCoverProps {
  imageText: IImageText;
  layoutBackground?: LayoutBackground;
  coverImageAlignment?: Alignment.Left | Alignment.Right;
}

export const ImageTextCover = ({
  imageText,
  layoutBackground,
  coverImageAlignment,
}: ImageTextCoverProps) => {
  const { title, blurb, images, button_text, button_style, button_URL } = imageText;
  return (
    <div className={cmsStyles.containerMarginS}>
      <Grid container className={coverImageAlignment === 'Left' ? styles.leftImage : ''}>
        <Grid item xs={12} md>
          <div
            className={cx(
              styles.content,
              layoutBackground === 'Light' ? styles.lightBackground : '',
            )}
          >
            {title && (
              <Typography variant="h5" className={styles.title}>
                {title}
              </Typography>
            )}
            {blurb && (
              <div className={styles.blurb}>
                <MarkdownCMSModule source={blurb} />
              </div>
            )}
            {button_URL && (
              <LinkElement linkText={button_text} link={button_URL} linkStyle={button_style} />
            )}
          </div>
        </Grid>
        {images?.length > 0 && images[0].url && (
          <Grid item xs={12} md className={styles.imageContainer}>
            {button_URL ? (
              <Link prefetch={false} href={button_URL} as={button_URL}>
                <a href={button_URL}>
                  <div className={styles.imageWrapper}>
                    <CmsImage
                      layout="fill"
                      objectFit="cover"
                      imageProps={images[0]}
                      altText={images[0].alternativeText || defaultAlt}
                      format="medium"
                      className={styles.img}
                      sizes="(max-width: 1200px) 50vw, 100vw"
                    />
                  </div>
                </a>
              </Link>
            ) : (
              <div className={styles.imageWrapper}>
                <CmsImage
                  className={styles.img}
                  layout="fill"
                  objectFit="cover"
                  imageProps={images[0]}
                  altText={images[0].alternativeText || defaultAlt}
                  format="medium"
                  sizes="(max-width: 1200px) 50vw, 100vw"
                />
              </div>
            )}
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default ImageTextCover;
