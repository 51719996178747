import ImageTextCover from '@/cmsComponents/ImageTextCover';
import { LinkElement } from '@/cmsComponents/LinkElement';
import { MarkdownCMSModule } from '@/cmsComponents/MarkdownCMSModule';
import { Alignment } from '@/cmsComponents/constants';
import cx from 'classnames';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import { IImageText } from 'types/cms';

import Grid from '@material-ui/core/Grid';
import { GridDirection } from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { LayoutBackground, LinkStyle } from 'components/CMS/constants';
import { defaultAlt } from 'components/Image';

import { ImageTextStyle } from '.';
import styles from './style.module.scss';

export interface IProps {
  data: IImageText;
  gridDirection?: GridDirection;
  style?: ImageTextStyle;
  alignment?: Alignment;
  layoutBackground?: LayoutBackground;
  coverImageAlignment?: Alignment.Left | Alignment.Right;
}
const SimpleImageSlider = dynamic(() => import('react-simple-image-slider'), { ssr: false });

const imageSizeMap = style => {
  switch (style) {
    case ImageTextStyle.OneColumn:
      return {
        height: 304,
        width: 766,
      };
    case ImageTextStyle.ImageRight:
      return {
        height: 304,
        width: 450,
      };
    case ImageTextStyle.ImageLeft:
      return {
        height: 304,
        width: 450,
      };
    case ImageTextStyle.TwoColumn:
      return {
        height: 216,
        width: 450,
      };
    case ImageTextStyle.ThreeColumn:
      return {
        height: 160,
        width: 292,
      };
    default:
      return {
        height: 304,
        width: 924,
      };
  }
};

export const Item = ({
  data,
  gridDirection,
  style,
  alignment,
  layoutBackground,
  coverImageAlignment,
}: IProps) => {
  const {
    title,
    images,
    blurb,
    button_style = LinkStyle.Button,
    button_URL,
    button_text = '',
  } = data;
  const imageSize = imageSizeMap(style);
  const sliderImages = images?.reduce<{ url: string }[]>((acc, curr) => {
    if (curr.url) {
      acc.push({ url: curr.url });
    }
    return acc;
  }, []);
  const isSlidesShow =
    sliderImages?.length > 1 &&
    style !== ImageTextStyle.TwoColumn &&
    style != ImageTextStyle.ThreeColumn;
  const isMultiItem = style === ImageTextStyle.ThreeColumn || style === ImageTextStyle.TwoColumn;

  return (
    <>
      {style === ImageTextStyle.Cover && (
        <ImageTextCover
          imageText={data}
          layoutBackground={layoutBackground}
          coverImageAlignment={coverImageAlignment}
        />
      )}
      {style !== ImageTextStyle.Cover && (
        <>
          <Grid
            container
            direction={gridDirection ?? 'column'}
            spacing={3}
            className={styles.itemContainer}
          >
            {images?.length > 0 && images[0].url && (
              <Grid item xs={12} md>
                {isSlidesShow ? (
                  <div className={cx(styles.img, styles.imageSlider)}>
                    <SimpleImageSlider
                      showNavs
                      showBullets
                      width={imageSize.width}
                      height={imageSize.height}
                      images={sliderImages}
                    />
                  </div>
                ) : button_URL ? (
                  <Link prefetch={false} href={button_URL} as={button_URL}>
                    <a href={button_URL}>
                      <div className={cx(styles.imgWrapper, styles.notImageSlider)}>
                        <Image
                          layout="fill"
                          className={styles.img}
                          src={images[0].url}
                          alt={images[0].alternativeText || defaultAlt}
                        />
                      </div>
                    </a>
                  </Link>
                ) : (
                  <div className={cx(styles.imgWrapper, styles.notImageSlider)}>
                    <Image
                      layout="fill"
                      className={styles.img}
                      src={images[0].url}
                      alt={images[0].alternativeText || defaultAlt}
                    />
                  </div>
                )}
              </Grid>
            )}
            <Grid item xs={12} md>
              {title && (
                <Typography variant="h6" className={styles.title}>
                  {title}
                </Typography>
              )}
              {blurb && (
                <div className={styles.blurb}>
                  <MarkdownCMSModule source={blurb} />
                </div>
              )}
              {button_URL && !isMultiItem && (
                <div>
                  <LinkElement
                    link={button_URL}
                    linkStyle={button_style}
                    linkText={button_text}
                    align={alignment}
                  />
                </div>
              )}
            </Grid>
          </Grid>
          {button_URL && isMultiItem && (
            <div>
              <LinkElement
                link={button_URL}
                linkStyle={button_style}
                linkText={button_text}
                align={alignment}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};
