import CMSChildrenContainer from '@/cmsComponents/ChildrenContainer';
import { Alignment, GridDirection, LayoutBackground } from '@/cmsComponents/constants';
import cmsStyles from '@/cmsComponents/style.module.scss';
import React from 'react';
import { IImageText } from 'types/cms';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Item } from './item';
import styles from './style.module.scss';

export enum ImageTextStyle {
  FullWidth = 'Full_Width',
  ImageLeft = 'Two_Column_Image_Left',
  ImageRight = 'Two_Column_Image_Right',
  OneColumn = 'One_Column',
  TwoColumn = 'Two_Column',
  ThreeColumn = 'Three_Column',
  Cover = 'Cover',
}

interface IProps {
  heading?: string;
  alignment?: Alignment;
  layout: ImageTextStyle;
  layout_background?: LayoutBackground;
  cover_image_alignment?: Alignment.Left | Alignment.Right;
  image_collections?: Array<IImageText>;
  image_collection?: IImageText;
}

const styleToGridColumn = (style: ImageTextStyle) => (style === ImageTextStyle.ThreeColumn ? 4 : 6);
const styleToGridDirection = (style: ImageTextStyle) => {
  switch (style) {
    case ImageTextStyle.ImageLeft:
      return GridDirection.Row;
    case ImageTextStyle.ImageRight:
      return GridDirection.RowReverse;
  }
  return GridDirection.Column;
};

export const CMSImageText: React.FC<IProps> = ({
  image_collection,
  image_collections,
  layout,
  layout_background,
  cover_image_alignment,
  heading,
  alignment,
}) => {
  return (
    <CMSChildrenContainer>
      <div className={cmsStyles.containerMarginS}>
        <div className={styles.heading}>
          {heading && (
            <Typography variant="h4" component="h5">
              {heading}
            </Typography>
          )}
        </div>
        {image_collections && (
          <div className={styles[layout]}>
            <Grid container spacing={3}>
              {image_collections.map(i => (
                <Grid key={i.id} item xs={12} md={styleToGridColumn(layout)}>
                  <div
                    className={
                      layout === ImageTextStyle.ThreeColumn || layout === ImageTextStyle.TwoColumn
                        ? styles.itemContent
                        : ''
                    }
                  >
                    <Item data={i} style={layout} alignment={alignment} />
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        )}

        {image_collection && (
          <div className={styles[layout]}>
            <Item
              data={image_collection}
              gridDirection={styleToGridDirection(layout)}
              style={layout}
              layoutBackground={layout_background}
              coverImageAlignment={cover_image_alignment}
              alignment={alignment}
            />
          </div>
        )}
      </div>
    </CMSChildrenContainer>
  );
};
