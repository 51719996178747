import Image from 'next/image';
import React from 'react';
import { ISingleMediaFile } from 'types/cms';

import { getImageUrl } from './getImageUrl';

export type ImageFormat = 'small' | 'medium' | 'large' | 'thumbnail';

interface CmsImageProps {
  imageProps: Pick<ISingleMediaFile, 'formats' | 'url'>;
  format: ImageFormat;
  quality?: number;
  altText: string;
  layout?: 'fixed' | 'fill' | 'intrinsic' | 'responsive';
  height?: number;
  width?: number;
  objectFit?: 'cover' | 'contain' | 'scale-down' | 'fill' | 'none';
  className?: string;
  priority?: boolean;
  sizes?: string;
}

const CmsImage = ({
  imageProps,
  altText,
  format,
  quality,
  layout,
  height,
  width,
  objectFit,
  priority,
  className,
  sizes,
}: CmsImageProps) => {
  const imageUrl = getImageUrl(imageProps.formats, format, imageProps.url);

  return imageUrl ? (
    <Image
      className={className}
      alt={altText}
      src={imageUrl}
      priority={priority}
      layout={layout}
      objectFit={objectFit}
      quality={quality}
      height={height}
      width={width}
      sizes={sizes}
    />
  ) : null;
};

export default CmsImage;
