export enum LinkStyle {
  Button = 'Button',
  HyperLink = 'HyperLink',
  MainCta = 'MainCTA',
}

export enum GridDirection {
  Row = 'row',
  RowReverse = 'row-reverse',
  Column = 'column',
  ColumnReverse = 'column-reverse',
}

export enum Alignment {
  Center = 'Center',
  Left = 'Left',
  Right = 'Right',
}

export enum LayoutBackground {
  Dark = 'Dark',
  Light = 'Light',
}
