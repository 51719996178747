/* eslint-disable react/jsx-boolean-value */
import SmallPrintTerms from '@/components/SmallPrintTerms';
import cx from 'classnames';
import Image, { StaticImageData } from 'next/image';
import React from 'react';

import Typography from '@material-ui/core/Typography';

import Quote from './img/quote.svg';
import css from './style.module.scss';

export const defaultAlt = 'Medrecruit Image';

const convertToArray = item => (Array.isArray(item) ? item : [item]);

export enum RegisterImgStyle {
  Img = 'img',
  OverlayImage = 'overlayImage',
}

interface ImageProps {
  img: StaticImageData;
  width: number;
  height: number;
  imgStyle?: RegisterImgStyle;
  imgAlt?: string;
  overlayCenteredText?: string | Array<string>;
  overlayTextInBox?: string | Array<string>;
  whiteBackground?: boolean;
}

export const ImageContainer = ({
  img,
  imgStyle,
  imgAlt,
  overlayCenteredText,
  overlayTextInBox,
  width,
  height,
  whiteBackground,
}: ImageProps) => {
  const text = overlayCenteredText || overlayTextInBox;
  const textStyle = overlayCenteredText ? css.centeredText : css.overlayCenteredTextInBox;
  const termsStyle = overlayTextInBox
    ? css.greyText
    : overlayCenteredText
    ? css.hidden
    : css.whiteText;
  return (
    <div className={css.imgContainer}>
      <div className={cx(imgStyle === RegisterImgStyle.OverlayImage ? css.overlayImage : css.img)}>
        <Image
          priority={true}
          width={width}
          height={height}
          src={img}
          alt={imgAlt || defaultAlt}
          title={imgAlt || defaultAlt}
        />
      </div>
      {text && (
        <div className={cx(css.textContainer, textStyle, whiteBackground && css.whiteBackground)}>
          {overlayTextInBox && <Quote />}
          {convertToArray(text).map((text, index) => (
            <Typography
              variant={overlayCenteredText ? 'h4' : index === 0 ? 'subtitle1' : 'caption'}
              key={`${text}-${index}`}
              display="block"
              component="p"
            >
              {text}
            </Typography>
          ))}
        </div>
      )}
      <div className={cx(css.finePrint, termsStyle, whiteBackground && css.paleBlueText)}>
        <SmallPrintTerms />
      </div>
    </div>
  );
};
