import { useAnalytics } from '@/app/utils/useAnalytics';
import { Alignment, LinkStyle } from '@/cmsComponents/constants';
import cx from 'classnames';
import React from 'react';

import Button from '@material-ui/core/Button';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import styles from './style.module.scss';

export interface LinkElementProps {
  linkText?: string;
  link: string;
  linkStyle?: LinkStyle;
  align?: Alignment;
  buttonClassName?: string;
  trackAction?: string;
}

export const LinkElement = ({
  linkText,
  link,
  linkStyle,
  align = Alignment.Center,
  buttonClassName,
  trackAction,
}: LinkElementProps) => {
  const track = useAnalytics();
  return (
    <div className={styles.container}>
      {linkStyle === LinkStyle.HyperLink ? (
        <div className={styles[align]}>
          <Button href={link} className={styles.hyperLink}>
            <span>{linkText}</span>
            <ArrowForwardIcon />
          </Button>
        </div>
      ) : (
        <div className={styles[align]}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() =>
              track('Click', {
                action: trackAction ?? 'CMS Button Click',
                'element text': linkText ?? '',
                'element type': 'button',
              })
            }
            href={link}
            className={cx(styles.button, styles[linkStyle || 'Button'], buttonClassName)}
          >
            <span className={styles.linkText}>{linkText}</span>
          </Button>
        </div>
      )}
    </div>
  );
};
