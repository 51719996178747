import React from 'react';
import { WithChildren } from 'types/withChildren';
import { combineClasses } from 'utils/stylesHelper';

import styles from './style.module.scss';

type CMSChildrenContainerType = WithChildren<{
  className?: string;
}>;

export const CMSChildrenContainer = ({ children, className }: CMSChildrenContainerType) => {
  return <div className={combineClasses(styles.childrenContainer, className)}>{children}</div>;
};

export default CMSChildrenContainer;
