import cmsStyles from '@/cmsComponents/style.module.scss';
import cx from 'classnames';
import React from 'react';
import ReactMarkdown from 'react-markdown';

import MarkdownRenderer from 'components/CMS/MarkdownRenderer';

import styles from './style.module.scss';

export const MarkdownCMSModule = ({
  source,
  className,
}: {
  source: string;
  className?: string;
}) => {
  return (
    <ReactMarkdown
      renderers={MarkdownRenderer}
      className={className ?? cx(styles.markdown, cmsStyles.markdownContent)}
      escapeHtml={false}
    >
      {source}
    </ReactMarkdown>
  );
};

export default MarkdownCMSModule;
