import { ImageFormat } from './CmsImage';

export const getImageUrl = (
  imageFormats,
  smallestImageFormat: ImageFormat,
  fallbackImageUrl?: string,
) => {
  if (!imageFormats) {
    return fallbackImageUrl;
  }

  if (imageFormats[smallestImageFormat]) {
    return imageFormats[smallestImageFormat].url;
  }

  let currentSmallestImageFormat = smallestImageFormat;
  const availableimageFormats: ImageFormat[] = ['thumbnail', 'small', 'medium', 'large'];

  while (!imageFormats[currentSmallestImageFormat]) {
    if (currentSmallestImageFormat === 'large') {
      return fallbackImageUrl;
    }

    currentSmallestImageFormat =
      availableimageFormats[availableimageFormats.indexOf(currentSmallestImageFormat) + 1];

    if (imageFormats[currentSmallestImageFormat]) {
      return imageFormats[currentSmallestImageFormat].url;
    }
  }
};
