import { PATH } from 'constants/path';
import Link from 'next/link';
import React from 'react';

import style from './style.module.scss';

export const SmallPrintTerms: React.FC = () => {
  return (
    <div className={style.smallPrint}>
      Our{' '}
      <Link prefetch={false} href={PATH.TERMS_AND_CONDITIONS} as={PATH.TERMS_AND_CONDITIONS}>
        <a className={style.link} href={PATH.TERMS_AND_CONDITIONS}>
          Terms of Use
        </a>
      </Link>{' '}
      and{' '}
      <Link prefetch={false} href={PATH.PRIVACY_POLICY} as={PATH.PRIVACY_POLICY}>
        <a className={style.link} href={PATH.PRIVACY_POLICY}>
          Privacy Policy
        </a>
      </Link>
    </div>
  );
};

export default SmallPrintTerms;
